@import "variables";
@import "bourbon/bourbon";
@import "fonts";
@import "mixins";
@import "helpers";

* { outline: none;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
  }

html {
  background: #fff;
  /* font-family: ; */
  font-size: 16px;
  line-height: 1.123;
  color: $color-text;
  cursor: default;
}

:root {
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  -o-text-size-adjust: none;
  text-size-adjust: none;
}

hr {
  display: block;
  border: none;
  background: #000;
  height: 1px; width: 3rem;
  margin: 1.14rem auto; padding: 0;
}

audio, canvas, iframe, img, svg, video {
  vertical-align: middle;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

// p { margin: 0 0 1em 0; }

.text {
  &-center    { text-align: center; }
  &-left      { text-align: left; }
  &-right     { text-align: right; }
  &-justify   { text-align: justify; }
  &-uppercase { text-transform: uppercase; }
  &-lowercase { text-transform: lowercase; }
  // &-note      { font-style: italic; color: #8d949f; }
}

a.more::after {
  @include mdi;
  content: '  \f213';
  display: inline;
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
}

#debug {
  @include opacity(0.8);
  /* display: none; */
  display: block;
  position: fixed;
  background: #e8514a;
  right: 0; bottom: 0;
  padding: 5px 10px;
  z-index: 200;
  font-size: 12px; line-height: 1.5;
  a { display: block; }
  button{
    display: block;
    padding: 0;
    height: auto;
    font-size: 12px;
    line-height: 13px;
    background: none;
  }
}

button, .button {
  @extend %no-selection;
  box-sizing: border-box; border-radius: 1.25em;
  display: inline-block; position: relative;
  border: none; border-collapse: collapse;
  &::-moz-focus-inner {border: none;}
  height: 2.5em; padding: 0 1.2em;
  /* font-family: ; */
  line-height: 2.55em; font-size: 16px;
  font-weight: $medium;
  text-align: center; vertical-align: middle; text-decoration: none !important;
  &:hover { cursor: pointer; }

  &.button {
    &_disabled { pointer-events: none !important; cursor: default; }
    &_default {
      @include opacity(0.6);
      background-color: transparent; color: #fff;
      &:hover { @include opacity(1); }
      &:active, &.active { background-color: transparent; color: #fff; }
      &:focus { background-color: transparent; }
      &.button_disabled, &:disabled, &[disabled] { background-color: transparent; color: fade(#fff, 30%); }
    }
    &_blue {
      background-color: $bg-button; color: #fff;
      &:hover { background-color: darken($bg-button, 10%); }
      &:active { background-color: $bg-button; }
      &:focus { background-color: $bg-button; }
      &.button_disabled, &:disabled, &[disabled] { background-color: $bg-button; color: fade(#fff, 30%); }
    }
    /* &_update {
      width: 20px;
      background: url('../img/ico-update.png') 50% 50% no-repeat $bg-button;
    } */
    &_search {
      padding-left: 3em;
      &::before {
        @include mdi;
        content: '\f3de';
        position: absolute;
        left: 0.8em; top: 50%;
        display: inline-block;
        margin: -0.45em 0 0 0;
        font-size: 22px;
        font-weight: $normal;
        color: inherit;
      }
    }
    &_remove {
      &::after {
        @include mdi;
        content: '\f223';
        display: inline-block;
        margin: 0 0 0 0.5em;
        font-size: inherit;
        font-weight: $bold;
        color: inherit;
      }
    }
    &_fb {
      &::before {
        @include mdi;
        content: '\f2d8';
        display: inline-block;
        margin: 0 0.5em 0 0;
        font-size: inherit;
        font-weight: $normal;
        color: inherit;
      }
    }
    &_social { font-size: 12px; }
  }
  &_file {
    overflow: hidden;
    input[type=file] {
      @include opacity(0);
      display: block;
      background: white;
      position: absolute; top: 0; right: 0;
      min-width: 100%; min-height: 100%;
      font-size: 100px;
      text-align: right;
      outline: none;
      cursor: inherit;
    }
  }

}
input,a,button{
  outline: none!important;
  &:hover,&:active,&:focus{
    outline: none!important;
  }
}
a{
  &:hover,&:active,&:focus{
    text-decoration: none;
  }
}

// Main
.all {
  &__body {}
}

form, .form-styling {
  // font-family: $font-roboto;
  font-size: 17px;
  // font-weight: $normal;
  color: $color-text;
  // .content { font-size: inherit; font-weight: inherit; color: inherit; }
  input, textarea {
    color: #000;
    // &[placeholder] { @include placeholder-styling; }
    &::-webkit-input-placeholder { @include placeholder-styling; }
    &:-moz-placeholder { @include placeholder-styling; }
    &::-moz-placeholder { @include placeholder-styling; }
    &:-ms-input-placeholder { @include placeholder-styling; }
  }
  input[type='text'], input.text, textarea, select {
    box-sizing: border-box;
    border-radius: 5px;
    // border: solid 1px $color-border;
    width: 100%; min-width: 260px; height: 40px;
    @include padding(10px 16px 10px 16px);
    background: #fff;
    // font-family: $font-exo2;
    font-size: 16px; line-height: 20px;
    // color: $color-input;
    vertical-align: middle;
    &:focus, &:active {}
  }
  textarea { height: auto; }
  select { min-width: 0; }

  label { display: block; margin: 0 0 3px 0; }
}

input, textarea {
  color: #000;
}

// MAIN STYLES
.container,.container-fluid{
  @include desktop {
    &.no-padd{
      padding-right:0;
      padding-left:0;
      .row{
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
}
body{
  font: normal 100% 'OpenSans-Regular', 'Arial', sans-serif;
  &.stop-scroll{
    overflow: hidden;
  }
}
// HEADER
.sanofi-header{
  height: 30px;
  text-align: right;
  padding-right: 20px;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  border-bottom: 1px solid $light-gray;
  background: #fff;
  z-index: 5;
  @include phone {
    padding-right: 15px;
  }
  a{
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    display: block;
  }
}
.header{
  border: 1px solid $light-gray;
  border-left: 0;
  border-right: 0;
  padding: 5px 0;
  background: #fff;
  font-size: $em-base;
  margin-top: 29px;
  &__#{logo}{
    height: 81px;
    @include mqMin(1280px) {
      padding: 0;
    }
    @include phone {
      height: 40px;
      img{
        max-width: 63px;
      }
    }
    a{
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      display: block;
    }
  }
  &__#{nav}{
    height: 81px;
    transition: transform .4s;
    @include mq(1279px) {
      position: fixed;
      left: 0;
      top: 0px;
      background: #fff;
      z-index: 6;
      height: 100%;
      transform: translateX(-100%);
      max-width: 320px;
      width: 100%;
      border-right: 1px solid $light-gray;
      padding-top: 50px;
      padding-bottom: 25px;
      overflow: auto;
      &.active{
        transform: translateX(0);
      }
    }
    @include mq(479px) {
      top: 81px;
    }
    &>ul{
      display: table;
      width: 100%;
      height: 100%;
      margin: 0;
      @include mq(1279px) {
        display: block;
        height: auto;
      }
      li{
        display: table-cell;
        text-align: center;
        vertical-align: middle;
        @include mq(1279px) {
          display: block;
          text-align: center;
          margin-bottom: 20px;
        }
        a{
          font-family: 'OpenSans-Semibold';
          text-transform: uppercase;
          font-size: em(15px);
          color: #333;
          position: relative;
          &:before{
            position: absolute;
            content: '';
            bottom: -37px;
            left: 0;
            width: 0%;
            display: block;
            height: 2px;
            background: $red;
            transition: width .4s;
            @include mq(1279px) {
              bottom: 0;
            }
          }
          &.active,&:hover{
            &:before{
              width: 100%;
            }
          }
        }
      }
    }
    .close-mob{
      position: absolute;
      right: 15px;
      top: 15px;
      color: $red;
      font-size: 25px;
      @include desktop {
        display: none;
      }
      @include mq(479px) {
        display: none;
      }
    }
    .moblie-navs{
      text-align: center;
      display: none;
      @include phone {
        display: block;
        &>ul{
          margin: 20px;
          a{
            color: $red;
            padding: 5px 0;
            display: inline-block;
          }
        }
      }
      .lang{
        margin-left: 10px;
        a{
          font-family: 'OpenSans-Regular';
          font-size : em(13px);
          display: inline-block;
          color: $red;
          &.active{
            color : #CCC;
          }
          &:first-child{
            padding-right: 5px;
            border-right: 1px solid $light-gray;
          }
          &:last-child{
            padding-left: 5px;
          }
        }
      }
    }
  }
  &__#{additionals}{
    height: 81px;
    @include mq(1279px) {
      float: right;
      text-align: right;
      position: relative;
    }
    @include phone {
      height: 40px;
    }
    .wrap{
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      @include mq(1279px) {
        padding-right: 50px;
      }
      &>*{
        display: inline-block;
      }
    }
    .search-trigger{
      padding: 0;
      background: none;
      border-radius: 0;
      font-size: 18px;
      &+.btn{
        margin-left: 10px;
        @include phone {
          display: none;
        }
      }
    }
    .lang{
      margin-left: 10px;
      @include phone {
        display: none;
      }
      a{
        font-family: 'OpenSans-Regular';
        font-size : em(11px);
        display: inline-block;
        color: $red;
        &.active{
          color : #CCC;
        }
        &:first-child{
          padding-right: 5px;
          border-right: 1px solid $light-gray;
        }
        &:last-child{
          padding-left: 5px;
        }
      }
    }
  }
  .burger{
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    display: none;
    z-index: 3;
    @include mq(1279px) {
      display: block;
    }
    span{
      background: $red;
      width: 24px;
    }
  }
}
// HEADER END

// FOOTER
.footer{
  font-size: $em-base;
  font-size: 14px;
  padding: 20px 15px;
  border-top: 1px solid $light-gray;
  font-family: 'Roboto', sans-serif;
  &__#{copy}{
     font-weight: 100;
    //  font-size : em(10px);
     line-height : 12px;
     color : #999;
     @include mqMin(1280px) {
       padding-left: 0;
     }
     @include phone {padding:0; margin-bottom:10px;}
     small{
       display: block;
       font-size: 100%;
       &+small{
         margin-top: 5px;
       }
     }
     a{
       color: $red;
       text-decoration: underline;
      //  font-size: 12px;
       margin-bottom: 10px;
       display: inline-block;
       line-height: normal;
       &:hover{
         text-decoration: none;
       }
       &:first-child{
         padding-right: 5px;
       }
       &:nth-child(3){
         padding-left: 3px;
       }
     }
  }
  &__#{navs}{
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    // font-size : em(13px);
    line-height : 20px;
    @include phone {padding:0; margin-bottom:10px;}
    ul{
      margin-bottom: 0;
    }
    a{
      color : $red;
    }
  }
  &__#{address}{
    font-family : 'Roboto', sans-serif;
    font-weight: 300;
    // font-size : em(15px);
    line-height : 24px;
    color : #999;
    @include phone {padding:0; margin-bottom:10px;}
    address{
      margin-bottom: 0;
    }
  }
  &__#{contacts}{
    font-family: Roboto,sans-serif;
    // font-size : em(15px);
    line-height: 19px;
    color : #999;
    @include phone {padding:0; margin-bottom:10px;}
    span{
      display: block;
    }
    a{
      color: #000;
      // font-size: em(17px);
      letter-spacing : 0.17px;
      padding-left: 4px;
    }
  }
  &__#{bottom} {
    padding: 0;
    font-weight: 300;
    line-height: 24px;
    color: #999;
    font-size: 12px;
    &>span{
      display: inline-block;
      margin-right: 10px;
    }
  }
  .health-warning{
    font-family: 'OpenSans-Light';
    font-size : em(19px);
    letter-spacing : 1.43px;
    color : #CCC;
    margin-top: 10px;
    @include mqMin(1280px) {
      padding-left: 0;
    }
    @include mq(1279px) {
      font-size: 15px;
    }
    @include mq(1023px) {
      font-size: 11px;
      margin-bottom: 15px;
    }
    @include phone {
      margin: 0;
      padding: 0;
      font-size: 8px;
    }
  }
  .up-btn{
    color: $middle-red;
    transition: .4s;
    position: fixed;
    width: 50px;
    height: 50px;
    right: 10px;
    bottom: 135px;
    display: none;
    z-index: 2;
    &:before{
      content: "\f05d";
      font-family: $materialFont;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 50px;
      height: 50px;
      border-radius: 50%;
      text-align: center;
      line-height: 50px;
      font-size: 28px;
      border: 1px solid $middle-red;
    }
  }
}
// FOOTER END

//----------------------
//  MAIN PAGE SLIDER
// ---------------------
body{
  &.main-page{
    #content{
      background: url('../img/main-slide.jpg') 0 0 no-repeat;
      background-size: 100%;
      background-position: 0 122px;
      @include mqMin(1601px) {
        background-size: cover;
        background-position: 0 60px;
      }
      @include mq(1500px) {
        background: url(../img/main-slide.jpg) -56px 155px no-repeat;
        background-size: 111%;
      }
      @media screen and (min-width: 1280px) and (max-width: 1366px) {
        background: url(../img/main-slide.jpg) 0px 80px no-repeat;
        background-size: 100%;
      }
      @include mq(1279px) {
        background: url(../img/main-slide.jpg) center bottom no-repeat;
        background-size: 115%;
      }
      @include mq(1023px) {
        background: url(../img/main-slide-tablet.jpg) center bottom no-repeat;
        // background-size: 115%;
      }
      @media screen and (min-width: 769px) and (max-width: 920px) {
        background: url(../img/main-slide-tablet.jpg) -50px 90px no-repeat;
        background-size: 900px 1080px;
      }
      @include phone {
        background: url(../img/main-slide-mob.jpg) center bottom no-repeat;
        background-size: 100%;
      }
      @media screen and (max-width: 767px) and (orientation:portrait) {
        background: url(../img/main-slide-mob.jpg) center bottom no-repeat;
        background-size: contain;
      }
    }
  }
}
.main-slider{
  // background: url('../img/main-slide.jpg') 0 0 no-repeat;
  // background-size: cover;
  font-size: $em-base;
  &__#{item}{
    &__#{product}{
      @include mqMin(768px) {
        padding-left: 0;
      }
      @include desktop {
        height: 600px;
        img{
          margin: 0 auto;
          @media screen and (min-width: 1280px) and (max-width: 1600px) {
            max-width: 500px;
          }
        }
      }
      @media screen and (min-width: 1280px) and (max-width: 1366px) {
        height: 460px;
        img{
          max-width: 400px;
        }
      }
      @include mq(1279px) {
        img{
            margin: 0 auto;
        }
      }
      @include mq(1023px) {
        img{
            max-width: 450px;
        }
      }
      @include phone {
        img{
          max-width: 280px;
        }
      }
      .product-slide{
        .slick-prev{
          left: 0px;
          z-index: 2;
          @include tablet {
            left: 15px;
          }
          @include mq(1023px) {
            left: 120px;
          }
          @include phone {
            left: 5px;
          }
          &::before{
            content: '';
            display: block;
            position: absolute;
            width: 28px;
            height: 62px;
            top: 50%;
            margin-top: -31px;
            background: url('../img/arrow.png') 0 0 no-repeat;
          }
        }
        .slick-next{
          right: 5px;
          z-index: 2;
          @include mq(1023px) {
            right: 120px;
          }
          @include phone {
            right: 5px;
          }
          &::before{
            content: '';
            display: block;
            position: absolute;
            width: 28px;
            height: 62px;
            top: 50%;
            margin-top: -31px;
            background: url('../img/arrow.png') 0 0 no-repeat;
            transform-origin: center, center;
            transform: rotate(180deg);
          }
        }
      }
      .slick-dots{
        bottom: 0;
        li{
          vertical-align: middle;
          width: 25px;
          height: 25px;
          button{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
          }
          &.slick-active{
            button{
              background: $red;
              &:before{
                width: 25px;
                height: 25px;
                left: 50%;
                top: 50%;
                transform: translate(-50%,-50%);
                content: "";
                background: rgba(254, 92, 92, 0.3);
                border-radius: 50%;
              }
            }
          }
          button{
            width: 10px;
            height: 10px;
            border-radius: 50%;
            border: 2px solid $red;
            &:before{
              content: "";
            }
          }
        }
      }
    }
    &__#{text}{
      padding-top: 20px;
      @include mq(1023px) {
        padding-top: 40px;
        margin-bottom: 120px;
      }
      @media screen and (max-width: 1023px) and (orientation:portrait) {
        padding-top: 40px;
        margin-bottom: 240px;
      }
      @include phone {
        margin-bottom: 0;
      }
      h1{
        margin: 0;
      }
      .heading{
        font-family: 'OpenSans-Regular';
        font-size: em(40px);
        color: $red;
        text-align: left;
        @media screen and (min-width: 1280px) and (max-width: 1366px) {
          font-size: 30px;
        }
        @include phone {
          font-size: em(25px);
        }
        span{
          font-size: 50px;
          @media screen and (min-width: 1280px) and (max-width: 1366px) {
            font-size: 36px;
          }
          @include phone {
            font-size: 35px;
          }
        }
      }
      .sub-heading{
        font-size: em(40px);
        font-family: 'OpenSans-Light';
        margin-bottom: 40px;
        @media screen and (min-width: 1280px) and (max-width: 1366px) {
          font-size: 26px;
          margin-bottom: 15px;
        }
        @include phone {
          font-size: em(25px);
        }
      }
    }
  }
}

// OFFICIAL PAGE
.official{
  padding-bottom: 50px;
  h1{
    margin: 25px 0px 20px;
  }
  .pl-30{
    padding-left: 30px;
  }
  .article__text{
    ul{
      list-style-type: disc;
      margin-left: 20px;
    }
    ol{
      list-style-type: decimal;
      margin-left: 25px;
      color: #000!important;
    }
  }
}
#blackout {
    background: rgba(0,0,0,0);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 5;
    left: 0;
    display: none;
}

@import "feedback";
@import "myths";
@import "products";
@import "articles";
@import "info";
@import "about";
@import "popup";
@import "media";

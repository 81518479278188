// ---------------------
//     ARTICLES Page
// ---------------------
.article-page{
  .back{
    font-size: 14px;
    padding-top: 45px;
    padding-bottom: 30px;
    .back-btn{
      padding-left: 55px;
      position: relative;
      color: $middle-red;
      transition: .4s;
      &:before{
        content: "\f04d";
        font-family: $materialFont;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 40px;
        height: 40px;
        border-radius: 50%;
        text-align: center;
        line-height: 40px;
        font-size: 28px;
        border: 1px solid $middle-red;
      }
      &:hover{
        color: $red;
        &:before{
          border-color: $red;
        }
      }
    }
  }
  &__#{content}{
    font-size: $em-base;
    h1{
      margin: 0;
      font-family: 'OpenSans-Light';
      font-size : em(42px);
      line-height : 60px;
      color : #231F20;
      margin-bottom: 15px;
    }
    time{
      font-size: em(15px);
      color: #999;
      display: inline-block;
      padding-bottom: 40px;
      border-bottom: 2px solid $light-red;
    }
    .authors{
      margin-top: 30px;
      .heading{
        font-size: em(21px);
        margin-bottom: 15px;
      }
      address{
        font-size: em(13px);
        color: $gray;
        font-family: 'Roboto', sans-serif;
        font-weight: 300;
        margin-bottom: 30px;
        p{
          margin: 0;
          &+p{
            margin-top: 15px;
          }
        }
      }
    }
    h3.heading-article{
      font-size: em(20px);
      font-family: 'OpenSans-Light';
      margin-bottom: 25px;
    }
    &>p{
      font-family: 'Roboto', sans-serif;
      font-weight: 300;
      font-size: em(17px);
      color: $gray;
    }
    .article-slider{
      margin: 40px 0 45px;
      &__#{item}{
        img{
          margin: auto;
        }
      }
      .slick-prev{
        left: 5px;
        z-index: 2;
        &::before{
          content: '';
          display: block;
          position: absolute;
          width: 28px;
          height: 62px;
          top: 50%;
          margin-top: -31px;
          background: url('../img/arrow.png') 0 0 no-repeat;
        }
      }
      .slick-next{
        right: 5px;
        z-index: 2;
        &::before{
          content: '';
          display: block;
          position: absolute;
          width: 28px;
          height: 62px;
          top: 50%;
          margin-top: -31px;
          background: url('../img/arrow.png') 0 0 no-repeat;
          transform-origin: center, center;
          transform: rotate(180deg);
        }
      }
      .slick-dots{
        li{
          &.slick-active{
            button{
              background: $middle-red;
            }
          }
          button{
            width: 5px;
            height: 5px;
            border-radius: 50%;
            border: 1px solid $middle-red;
            display: inline-block;
            &:before{
              content: "";
            }
          }
        }
      }
    }
  }
  &__#{other-articles}{
    font-size: $em-base;
    padding-bottom: 40px;
    &.icon-line{
      &:before{
        background-image: url('../img/book-icon.png');
      }
    }
    .other-articles-slider{
      padding-bottom: 30px;
      &__#{item}{
        &>.row{
          margin: 0;
        }
      }
    }
    .article{
      padding: 0;
      border:1px solid $light-gray;
      width: 100%;
      img{
        width: 100%;
      }
      .caption{
        padding: 30px;
        time{
          font-size: 13px;
          color: $gray;
        }
        h3{
          font-family: 'OpenSans-Light';
          font-size: em(24px);
          margin-bottom: 50px;
          line-height: normal;
        }
        a.read-more{
          font-size: em(14px);
          font-family: 'OpenSans-Semibold';
          color: $red;
        }
      }
    }
    .more-articles{
      margin: 50px auto;
      max-width: 200px;
      display: block;
      width: 100%;
      text-align: center;
      span{
        display: block;
        margin: 0 auto;
        color: $red;
      }
    }
    .slick-prev{
      left: -45px;
      z-index: 2;
      &::before{
        content: '';
        display: block;
        position: absolute;
        width: 28px;
        height: 62px;
        top: 50%;
        margin-top: -31px;
        background: url('../img/arrow.png') 0 0 no-repeat;
      }
    }
    .slick-next{
      right: -45px;
      z-index: 2;
      &::before{
        content: '';
        display: block;
        position: absolute;
        width: 28px;
        height: 62px;
        top: 50%;
        margin-top: -31px;
        background: url('../img/arrow.png') 0 0 no-repeat;
        transform-origin: center, center;
        transform: rotate(180deg);
      }
    }
    .slick-dots{
      li{
        &.slick-active{
          button{
            background: $middle-red;
          }
        }
        button{
          width: 5px;
          height: 5px;
          border-radius: 50%;
          border: 1px solid $middle-red;
          display: inline-block;
          &:before{
            content: "";
          }
        }
      }
    }
  }
}

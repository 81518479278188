.feedback{
  padding: 60px 0;
  &__#{text}{
    font-size: $em-base;
    font-family: 'OpenSans-Light';
    margin-bottom: 45px;
    p{
      font-size: em(20px);
    }
    small{
      font-family: 'Roboto', sans-serif;
      font-weight: 300;
      font-size: em(17px);
      color: $gray;
    }
  }
  &__#{form}{
    font-size: $em-base;
    h3{
      font-family: 'OpenSans-Light';
      font-size: em(24px);
      margin: 0 0 25px;
      @include phone {
        font-size: 19px;
      }
    }
    form{
      div[class^="col-"]{
        @media screen and (min-width: 1024px){
          &:nth-child(odd){
            padding-left: 0;
          }
          &:nth-child(even){
            padding-right: 0;
          }
        }
      }
      .last{
        margin-top: 28px;
      }
    }
    .form-group{
      position: relative;
      &.error{
        input {border-color: $light-red;}
        span.error{display: block;}
      }
      label{
        font-size: em(16px);
        color: #999;
        font-family: 'OpenSans-Regular';
        font-weight: normal;
      }
      input,textarea{
        border-radius: 0;
        box-shadow: none;
        border: 1px solid $light-gray;
        width: 100%;
        height: 40px;
        padding: 10px 16px;
      }
      input.capcha-field {
        max-width: 270px;
      }
      textarea{
        height: 105px;
      }
      span.error{
        position: absolute;
        bottom: 10px;
        left: 16px;
        color: $red;
        font-size: em(16px);
        font-family: 'OpenSans-Regular';
        display: none;
      }
    }
    .capcha{
      .get-code{
        display: block;
        text-decoration: underline;
        color: $red;
      }
    }
    .custom-radio{
      display: block;
    }
  }
}
.feedback-bottom-text{
  font-size: $em-base;
  margin: 35px 0 50px;
  h5{
    font-size: em(21px);
    margin: 15px 0px 10px;
  }
  p{
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    font-size: em(17px);
    color: $gray;
  }
  small{
    color:#000;
    font-size: em(17px);
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    &>span{
      color: $red;
    }
  }
}
.custom-radio{
  position: relative;
  display: inline-block;
  padding-left: 30px;
  &:before{
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -10px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid $red;
  }
  &.checked{
    &:after{
      content: "";
      display: block;
      position: absolute;
      left: 4px;
      margin-top: -6px;
      top: 50%;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: $red;
    }
  }
  &:hover{
    cursor: pointer;
  }
  input{
    position: absolute;
    visibility: hidden;
    z-index: -1;
  }
}

// ---------------------
//     Products page
// ---------------------
.products{
  padding-bottom: 70px;
  &__#{hero}{
    background: url('../img/products-hero.png') center center no-repeat;
    background-size: cover;
    font-size: $em-base;
    .heading{
      margin: 80px auto 35px;
      @include mq(1440px) {
        margin: 25px auto 25px;
        font-size: 32px;
      }
    }
    &__#{links}{
      margin: 0 auto;
      width: 400px;
      height: 50px;
      border-radius: 25px;
      border: 1px solid $red;
      background: #fff;
      font-size: em(16px);
      overflow: hidden;
      position: relative;
      z-index: 1;
      @media screen and (max-width: 479px) {
        width: 260px;
        height: auto;
      }
      .sliding{
        position: absolute;
        height: 100%;
        width: 200px;
        background: $red;
        border-radius: 25px;
        left: 0;
        top: 0;
        // transform: translateX(200px);
        z-index: 0;
      }
      a{
        color: $red;
        display: block;
        width: 50%;
        float: left;
        height: 100%;
        line-height: 48px;
        padding-top: 0;
        padding-bottom: 0;
        // position: relative;
        border-radius: 25px;
        // z-index: 1;
        transition: all .4s;
        &:hover{
          color: #fff;
          background:rgba(254,92,92,0.6);
        }
        &.active{
          color: #fff;
          background: $red;
          box-shadow: none;
          &:hover{
            background: $red;
          }
        }
        @media screen and (max-width: 479px) {
          width: 100%;
        }
      }
    }
    .anchor-nav{
      @include mq(1440px) {
        margin: 18px 0 0;
      }
    }
  }
  &__#{list}{
    padding: 30px 0 40px;
    @include mq(1440px) {
      padding-top: 10px;
    }
    &>.container>.row>.col-xs-12{
      @include mqMin(1280px) {
        padding-left: 0;
      }
    }
    .prod-thumb{
      transition: border-color .5s;
      @media screen and (min-width: 768px) and (max-width: 1023px) {
        width: 33.33333%;
        &:first-child{
          padding-right: 0;
        }
        &:nth-child(2){
          padding: 0;
        }
        &:last-child{
          padding-left: 0;
        }
      }
      @include desktop {
        border: 1px solid $light-gray;
      }
      &:nth-child(2){
        border-left-color: #fff;
        border-right-color: #fff;
        &:hover{
          border:1px solid $light-red;
        }
      }
      &:hover{
        border-color: $light-red;
      }
      &__#{inner}{
        @include mq(1279px) {
          border: 1px solid $light-gray;
        }
        figure{
          text-align: center;
          margin: 20px 0 5px;
          img{
            max-width: 200px;
          }
        }
        .caption{
          padding: 0;
          color: $color-text;
          padding: 0px 15px 35px;
          h3{
            font-size: em(24px);
          }
          .info{
            font-family: 'OpenSans-Light';
            font-size: em(17px);
          }
          .text{
            font-size: em(15px);
            color: $gray;
            margin: 10px 0 30px;
          }
        }
      }
    }
  }
  &__#{recommendations}{
    font-size: $em-base;
    p{
      margin: 0;
      font-size: em(17px);
      color: $gray;
      font-family: 'Roboto', sans-serif;
      font-weight: 300;
      &+p{
        margin-top: 25px;
      }
    }
    &+.products__recommendations{
      margin-top: 25px;
    }
  }
  &__#{usage}{
    font-size: $em-base;
    .prod-thumb{
      &__#{inner}{
        text-align: center;
        figure{
          margin: 0 auto;
          width: 220px;
          height: 220px;
          position: relative;
          z-index: 1;
          border-radius: 50%;
          border: 1px solid $light-red;
          overflow: hidden;
          img{
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
          }
        }
        .caption{
          padding: 0px 15px 0px;
          h3{
            color: $red;
          }
          .text{
            font-size: em(17px);
          }
        }
      }
    }
  }
  &__#{components}{
    .icon-line{
      &:before{
        background-image: url('../img/stream-icon.png');
      }
    }
    .prod-thumb{
      &__#{inner}{
        text-align: center;
        figure{
          margin: 0 auto;
          width: 220px;
          height: 220px;
          position: relative;
          z-index: 1;
          border-radius: 50%;
          border: 1px solid $light-red;
          overflow: hidden;
          img{
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
          }
        }
        .caption{
          padding: 0px 15px 0px;
          .text{
            font-size: em(17px);
          }
        }
      }
    }
  }
}

.literature{
  font-size: $em-base;
  margin-top: 20px;
  // color: #ccc;
  h2{
    text-align: left;
    font-size: em(18px);
    margin-bottom: 10px;
    text-transform: uppercase;
    display: inline-block;
    padding-top: 3px;
    border-top: 2px solid $red;
  }
  p{
    font-size: em(14px);
    color: $gray!important;
  }
}

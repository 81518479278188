// ----------------------------
//     INFO PAGE
// ----------------------------
.info{
  &__#{articles}{
    font-size: $em-base;
    padding-top: 70px;
    .article{
      padding: 0;
      border:1px solid $light-gray;
      img{
        width: 100%;
      }
      .caption{
        padding: 30px;
        time{
          font-size: 13px;
          color: $gray;
        }
        h3{
          font-family: 'OpenSans-Light';
          font-size: em(24px);
          margin-bottom: 50px;
          line-height: normal;
        }
        a.read-more{
          font-size: em(14px);
          font-family: 'OpenSans-Semibold';
          color: $red;
        }
      }
    }
    .more-articles{
      margin: 50px auto;
      max-width: 200px;
      display: block;
      width: 100%;
      text-align: center;
      span{
        display: block;
        margin: 0 auto;
        color: $red;
      }
    }
  }
  &__#{banners}{
    font-size: $em-base;
    &__#{item}{
      border: 1px solid $light-red;
      background: #fff url('../img/info-banner-bg.png') 0 0 no-repeat;
      background-size: cover;
      &:first-child{
        border-left: 0;
        border-right: 0;
      }
      &:last-child{
        border-right: 0;
      }
      .banner-text{
        width: 50%;
        float: left;
        padding-left: percs(91,683);
        h3{
          font-size: em(42px);
          color: $red;
        }
        p{
          font-size : em(24px);
            font-family: 'OpenSans-Light';
          line-height : 33px;
          color : #231F20;
          margin-bottom: 20px;
        }
      }
      .banner-img{
        width: 50%;
        float: left;
        img{
          max-width: 300px;
        }
      }
    }
  }
}

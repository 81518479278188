$phone-width: 320px;
$tablet-width: 768px;
$desktop-width: 1280px;

@mixin phone {
  @media screen and (min-width: #{$phone-width}) and (max-width: #{$tablet-width - 1px}) {
    @content;
  }
}
@mixin tablet {
  @media screen and (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: #{$desktop-width}) {
    @content;
  }
}

@mixin mq($width) {
  @media screen and (max-width: $width) {
    @content;
  }
}
@mixin mqMin($width) {
  @media screen and (min-width: $width) {
    @content;
  }
}
@mixin mqH($width) {
  @media screen and (max-height: $width) {
    @content;
  }
}
@mixin laptop {
  @media screen and (min-width: 1280px) and (max-width: 1440px) and (min-height: 600px) and (max-height: 760px) {
    @content;
  }
}

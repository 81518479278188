.custom-modal{
  .modal-dialog{
    background: url('../img/modal-bg.png') 0 0 no-repeat;
    background-size: cover;
    @include mqMin(768px) {
      // width: 760px;
      // min-height: 600px;
      width: 715px;
      min-height: 568px;
    }
    @include mqMin(1281px) {
      width: 740px;
      min-height: 585px;
      margin: 10px auto 0;
    }
    @include phone {
      background: url('../img/modal-bg-mob.png') 0 0 no-repeat;
      background-size: 101% 100%;
      background-position: -2px 0;
      margin: 0;
    }
    .modal-content{
      background: none;
      box-shadow: none;
      border: none;
      font-size: $em-base;
      max-width: 560px;
      margin: 0 auto;
      @include mqMin(1281px) {
        max-width: 425px;
      }
      @include mq(1355px) {
        // max-width: 520px;
        max-width: 410px;
      }
      @include mq(1279px) {
        max-width: 445px;
      }
      ol{
        list-style-type: decimal;
        margin-left: 25px;
      }
    }
    .modal-header{
      border-bottom: 0;
      padding-top: 66px;
      margin-bottom: 10px;
      position: relative;
      h4{
        font-family: 'OpenSans-Light';
        font-size: em(26px);
        @include phone {
          font-size: 21px;
        }
      }
      .number{
        position: absolute;
        font-size: em(145px);
        line-height: 145px;
        color: $light-red;
        font-family: 'OpenSans-bold';
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        z-index: -1;
        @include phone {
          font-size: em(140px);
        }
      }
      .close{
        position: absolute;
        right: -120px;
        width: 50px;
        height: 50px;
        display: block;
        opacity: 1;
        @include phone {
          width: 30px;
          height: 30px;
          right: 10px;
          top: 10px;
        }
        &:before,&:after{
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          width: 70px;
          height: 2px;
          background: $red;
          display: block;
          transform: translate(-50%,-50%) rotate(45deg);
          transform-origin: center center;
          @include phone {
            width: 40px;
          }
        }
        &:after{
          transform: translate(-50%,-50%) rotate(-45deg);
        }
      }
    }
    .modal-body{
      font-family: 'Roboto', sans-serif;
      font-weight: 300;
      font-size: em(17px);
      max-height: 470px;
      overflow: auto;
      padding-top: 0;
      max-height: 310px;
      @media screen and (min-width: 920px) and (max-width: 1280px) {
        max-height: 270px;
      }
      @include phone {
        max-height: none;
        padding-bottom: 180px;
      }
    }
  }
}
#leave{
  .modal-header{
    padding: 25px;
    .close{
      position: absolute;
      right: 20px;
      top: 0;
    }
  }
  .modal-body{
    padding: 25px;
    p{
      margin-bottom: 25px;
    }
    .btn+.btn{
      margin-left: 20px;
      @include phone {
        margin: 15px auto;
      }
    }
  }
}

@mixin opacity($opacity) {
  -ms-filter: unquote("progid:DXImageTransform.Microsoft.Alpha(Opacity=#{round($opacity * 100)})");
  filter: unquote("alpha(opacity=#{round($opacity * 100)})");
  opacity: $opacity;
}

@mixin mdi {
  font: {
    family: "Material Design Icons";
    weight: normal;
    size: inherit;
    style: normal;
    variant: normal;
  }
  line-height: 1;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
}

@function pct($target, $context) {
  @if not unitless($target) {
    $target: strip-units($target);
  }
  @if not unitless($context) {
    $context: strip-units($context);
  }
  @return ($target / $context) * 100%;
}

@function percs($target, $context) {
  @return pct($target, $context);
}

%no-selection {
  &::-moz-selection {
    background: transparent;
    text-shadow: none;
  }

  &::selection {
    background: transparent;
    text-shadow: none;
  }
}

@mixin placeholder-styling { color: #fff !important; @include opacity(100); }

// .container,.container-fluid{
//   &.no-padd{
//     padding-right:0;
//     padding-left:0;
//     .row{
//       margin-left: 0;
//       margin-right: 0;
//     }
//   }
// }

// BOOTSTRAP EQ HEIGHT
/* USAGE
<div class="row">
  <div class="row-height">
    <div class="col-xs-2 col-xs-height col-xs-middle">
      <div class="inside"></div>
    </div>
    <div class="col-xs-4 col-lg-5 col-xs-height col-xs-middle">
      <div class="inside"></div>
    </div>
  </div>
</div>
*/

/* content styles */

.inside-full-height {
  /*
  // if you want to give content full height give him height: 100%;
  // with content full height you can't apply margins to the content
  // content full height does not work in ie http://stackoverflow.com/questions/27384433/ie-display-table-cell-child-ignores-height-100
  */
  height: 100%;
  margin-top: 0;
  margin-bottom: 0;
}

/* columns of same height styles */

.row-height {
  display: table;
  table-layout: fixed;
  height: 100%;
  width: 100%;
}
.col-height {
  display: table-cell;
  float: none;
  height: 100%;
}
.col-top {
  vertical-align: top;
}
.col-middle {
  vertical-align: middle;
}
.col-bottom {
  vertical-align: bottom;
}

@media (min-width: 480px) {
  .row-xs-height {
    display: table;
    table-layout: fixed;
    height: 100%;
    width: 100%;
  }
  .col-xs-height {
    display: table-cell;
    float: none;
    height: 100%;
  }
  .col-xs-top {
    vertical-align: top;
  }
  .col-xs-middle {
    vertical-align: middle;
  }
  .col-xs-bottom {
    vertical-align: bottom;
  }
}

@media (min-width: 768px) {
  .row-sm-height {
    display: table;
    table-layout: fixed;
    height: 100%;
    width: 100%;
  }
  .col-sm-height {
    display: table-cell;
    float: none;
    height: 100%;
  }
  .col-sm-top {
    vertical-align: top;
  }
  .col-sm-middle {
    vertical-align: middle;
  }
  .col-sm-bottom {
    vertical-align: bottom;
  }
}

@media (min-width: 992px) {
  .row-md-height {
    display: table;
    table-layout: fixed;
    height: 100%;
    width: 100%;
  }
  .col-md-height {
    display: table-cell;
    float: none;
    height: 100%;
  }
  .col-md-top {
    vertical-align: top;
  }
  .col-md-middle {
    vertical-align: middle;
  }
  .col-md-bottom {
    vertical-align: bottom;
  }
}

@media (min-width: 1280px) {
  .row-lg-height {
    display: table;
    table-layout: fixed;
    height: 100%;
    width: 100%;
  }
  .col-lg-height {
    display: table-cell;
    float: none;
    height: 100%;
  }
  .col-lg-top {
    vertical-align: top;
  }
  .col-lg-middle {
    vertical-align: middle;
  }
  .col-lg-bottom {
    vertical-align: bottom;
  }
}

#blackout{
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.full-height{
  height: 100%;
}

.burger{
  display: block;
  span{
    width: 15px;
    height: 4px;
    background-color: #000;
    display: block;
    &:nth-child(2){
      margin: 6px 0;
    }
  }
}

#content {
  min-height: calc(100vh - 110px);
}
.footer {
  height: 110px;
  @include mq(1279px) {
    height: auto;
  }
}

h1{
  &.heading{
    text-align: center;
    font-size: 42px;
    color: $red;
    text-transform: uppercase;
    margin: 0;
    line-height: normal;
    @include phone {
      font-size: 25px;
    }
  }
}
h2{
  &.heading{
    text-align: center;
    font-size: 36px;
    font-family: 'OpenSans-Light';
    margin: 0;
    line-height: normal;
    margin: 0px 0 30px;
    @include phone {
      font-size: 23px;
    }
  }
}

.btn{
  &.btn-default{
    border: none;
    font-family: 'OpenSans-Semibold';
    font-size : 14px;
    line-height : 42px;
    color : #FFF;
    text-align: center;
    height: auto;
    padding: 0px 19px;
    border-radius: 25px;
    transition: box-shadow .3s;
    position: relative;
    &:hover{
      // box-shadow: 0px 3px 1px #ccc;
      box-shadow: 4px 4px 0px $light-red, -4px 4px 0px $light-red, 4px -4px 0px $light-red, -4px -4px 0px $light-red;
    }
    &:active,&:focus{
      background: $red;
      box-shadow: none;
      color: #fff;
    }
  }
  &--#{red}{
    background: $red;
    &:hover{
      background: darken($red,5%);
    }
  }
}
@keyframes pulse {
  from{transform: scale(1,1); opacity:1;};
  to{transform: scale(1.1,1.5); opacity:0;};
}
// svg icons
.icon{
    color: #df958f;
    width: 1em;
    height: 1em;
    vertical-align: -0.15em;
    fill: currentColor;
    overflow: hidden;
    transition: all .5s;
    stroke-width: 1px;
   &--#{line}{
     fill:none;
     stroke:#ccc;
     stroke-miterlimit:10;
     stroke-dasharray:12;
     &:hover{
       stroke-width: 2px;
       stroke:#df958f;
     }
     &.active{
       stroke:$red;
     }
   }
   &--#{slider-arrow}{
     fill:none;
     stroke:#dd9891;
     stroke-miterlimit:10;
   }
}

// prod-thumb
.prod-thumb{
  font-size: $em-base;
  &__#{inner}{
    border: none;
    border-radius: 0;
    padding: 0;
    margin: 0;
    figure{
      text-align: center;
    }
    .caption{
      padding: 0;
      color: $color-text;
      padding: 0px;
      h3{
        font-size: em(24px);
      }
      .text{
        font-size: em(15px);
        color: $gray;
      }
    }
  }
}

// icon-line
.icon-line{
  margin-top: 70px;
  padding-top: 70px;
  border-top: 2px solid $light-red;
  position: relative;
  &:before{
    position: absolute;
    content: '';
    left: 50%;
    top: -35px;
    transform: translateX(-50%);
    width: 70px;
    height: 70px;
    border-radius: 50%;
    border: 2px solid $light-red;
    background: #fff url('../img/bath-icon.png') center center no-repeat;
  }
}

// anchors navs
.anchor-nav{
  display: table;
  width: 100%;
  margin: 45px 0 0;
  font-family: 'Roboto', sans-serif;
  &>li{
    display: table-cell;
    text-align: center;
    width: 33.333%;
    @include phone {
      display: block;
      width: 100%;
    }
    a{
      display: inline-block;
      padding-bottom: 25px;
      font-size: em(16px);
      color: #000;
      position: relative;
      transition: all .4s;
      &:before{
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        height: 2px;
        background: $red;
        width: 0;
        transition: all .4s;
      }
      &.active,&:hover{
        color: $red;
        &:before{
          width: 100%;
        }
      }
    }
  }
}


.slick-next:before, .slick-prev:before{
  opacity: 1;
}

// ----------------------------
//     MYTHS PAGE
// ----------------------------
.myths{
  font-size: $em-base;
  &__#{slider}{
    position: relative;
    &:after{
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      display: block;
      width: 325px;
      height: 100%;
      background: url('../img/myth-grad.png') 0 0 repeat-y;
      @include mq(1279px) {
        width: 200px;
      }
      @include mq(1023px) {
        width: 150px;
      }
      @include phone {
        display: none;
      }
    }
    &.gradHide{
      &:after{
        display: none;
      }
    }
    .slick-prev{
      left: -45px;
      z-index: 2;
      @include mq(1279px) {
        left: 0;
      }
      &::before{
        content: '';
        display: block;
        position: absolute;
        width: 28px;
        height: 62px;
        top: 50%;
        margin-top: -31px;
        background: url('../img/arrow.png') 0 0 no-repeat;
      }
    }
    .slick-next{
      right: -45px;
      z-index: 2;
      @include mq(1279px) {
        right: 0;
      }
      &::before{
        content: '';
        display: block;
        position: absolute;
        width: 28px;
        height: 62px;
        top: 50%;
        margin-top: -31px;
        background: url('../img/arrow.png') 0 0 no-repeat;
        transform-origin: center, center;
        transform: rotate(180deg);
      }
    }
    .slick-dots{
      bottom: 0;
      li{
        margin: 0 10px;
        position: relative;
        vertical-align: middle;
        &:before{
          position: absolute;
          right: -100%;
          top: 50%;
          content: "";
          width: 100%;
          height: 1px;
          background: #ccc;
        }
        &:first-child{
          display: none;
        }
        &:last-child{
          &:before{
            display: none;
          }
        }
        &.slick-active{
          width: 28px;
          height: 28px;
          button{
            background: $red;
            color: #fff;
            border: 4px solid $light-red;
            width: 28px;
            height: 28px;
          }
        }
        button{
          color:#ccc;
          border-radius: 50%;
          border: 1px solid #ccc;
          font-size: 14px;
          line-height: 18px;
          padding: 0;
          background: #fff;
          text-align: center;
          &:before{
            display: none;
          }
        }
      }
    }
    .slick-next,.slick-prev{
      @include mq(1279px) {
        transform: none;
        top: auto;
        bottom: 45px;
      }
    }
    &__#{item}{
      vertical-align: middle;
      position: relative;
      @include desktop {
        min-height: 455px!important;
      }
      @include mq(1279px) {
        margin-bottom: 50px;
      }
      @include phone {
        height: auto!important;
      }
      .icon{
        @include phone {
          display: none !important;
        }
      }
      .center-wrap{
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
        @include mq(919px) {
          padding-left: 30px;
        }
        @include phone {
          padding-left: 0;
          position: static;
          top:auto;
          transform: none!important;
        }
      }
      h1{
        font-size: em(36px);
        color: $red;
        text-transform: uppercase;
        margin: 0 0 30px;
        @include phone {
          font-size: 25px;
        }
      }
      p{
        font-size: 16px;
        font-family: 'OpenSans-Light';
        &+.btn{
          margin-top: 50px;
        }
      }
      .btn{
        transition: none;
        &:hover{
          box-shadow: none;
          cursor: default;
          background: $red;
        }
        &.disabled{
          cursor: default;
          &:hover{
            cursor: default;
          }
        }
      }
      &.slick-current{
        .icon{
          stroke:$red;
        }
        .btn{
          &:hover{
            cursor: pointer;
            background: darken($red,5%);
            box-shadow: 4px 4px 0px $light-red, -4px 4px 0px $light-red, 4px -4px 0px $light-red, -4px -4px 0px $light-red;
          }
        }
        .myths__slider__item__number{
          border: 1px solid #FE5C5C;
          &:before{
            opacity: 0;
          }
          .wrap{
            border: none;
            p{
              font-size: em(56px);
            }
          }
        }
      }
      &.active-line{
        .icon{
          stroke:$red;
        }
      }
      // start-slide
      &.start-slide{
        @include phone {
          margin-top: 25px;
        }
        .icon{
          font-size: 300px;
          position: absolute;
          font-size: 391px;
          position: absolute;
          right: 0;
          top: 115px;
          transform: rotate(11deg) skewX(-9deg);
          z-index: 0;
          @media screen and (min-width: 920px) and (max-width: 1279px) {
            font-size: 460px;
            position: absolute;
            right: -157px;
            top: 72px;
            transform: rotate(12deg) skewX(-14deg);
          }
          @include mq(919px) {
            transform: rotate(8deg) skewX(-9deg) scaleX(1.17) translate(93px,21px);
            stroke-dasharray: 10px;
          }
        }
        .center-wrap{
          max-width: 520px;
          margin: 0 auto;
        }
        .btn{
          @include phone {
            display: none;
          }
        }
      }
      // first-slide
      &.first-slide{
        .icon{
          font-size: 600px;
          position: absolute;
          // top: 15px;
          top: 3%;
          left: 73px;
          height: 0.4em;
          @media screen and (min-width: 1601px) and (min-height: 950px) {
            top: 14%;
            left: 53px;
          }
          @media screen and (min-width: 920px) and (max-width: 1279px) {
            font-size: 785px;
            position: absolute;
            top: -1%;
            left: 180px;
            height: .3em;
            transform: skew(-29deg) scaleX(1.1);
            stroke-dasharray: 8;
          }
          @include mq(919px) {
            left: 136px;
            height: .4em;
            top: 50%;
            transform: translateY(-100%) scaleX(1.1);
          }
        }
        .myths__slider__item__img{
          @media screen and (min-width: 920px) and (max-width: 1279px) {
            transform: translate(50%,-31%);
            right: 50%;
          }
        }
      }
      // second-slide
      &.second-slide{
        .icon{
          font-size: 620px;
          position: absolute;
          // top: 15px;
          top: 2.2%;
          left: 36px;
          height: .4em;
          -ms-transform: rotate(-9deg) skewX(5deg);
          transform: rotate(-5deg) skewX(8deg);
          @media screen and (min-width: 1601px) and (min-height: 950px) {
            top: 16.2%;
          }
          @media screen and (min-width: 920px) and (max-width: 1279px) {
            font-size: 749px;
            position: absolute;
            top: 1%;
            left: 140px;
            height: .4em;
            -ms-transform: rotate(-9deg) skewX(5deg);
            transform: rotate(-7deg) skewX(14deg) scaleX(1.3);
            stroke-dasharray: 7;
          }
          @include mq(919px) {
            top: 50%;
            transform: translateY(-93%) scaleX(1.3) rotate(-6deg) skewX(8deg);
            left: 130px;
          }
        }
        .center-wrap{
          transform: translateY(-60%);
        }
        .myths__slider__item__number{
          display: block;
          margin-bottom: 25px;
        }
        .myths__slider__item__img{
          right: 70px;
          @media screen and (min-width: 920px) and (max-width: 1279px) {
            transform: translate(50%,-31%);
            right: 50%;
          }
          img{
            width: 100%;
          }
        }
      }
      // .third-slide
      &.third-slide{
        .icon{
          font-size: 869px;
          position: absolute;
          top: 29.2%;
          left: -80px;
          height: .4em;
          transform: rotate(13deg) skewX(-32deg);
          @media screen and (min-width: 1601px) and (min-height: 952px) {
            // top: 18%;
          }
          @media screen and (min-width: 920px) and (max-width: 1279px) {
            font-size: 890px;
            position: absolute;
            top: 26.2%;
            left: 113px;
            height: .4em;
            -ms-transform: rotate(13deg) skewX(-32deg);
            transform: rotate(5deg) skewX(-37deg) scaleX(1.8);
            stroke-dasharray: 7;
          }
          @include mq(919px) {
            transform: scaleX(1.26) rotate(11deg) skewX(-32deg) translateX(103px);
            stroke-dasharray: 8;
            left: -104px;
          }
        }
        .center-wrap{
          transform: translateY(-31%);
        }
        .myths__slider__item__img{
          top: 70px;
          left: 20px;
          width: 40%;
          @media screen and (min-width: 920px) and (max-width: 1279px) {
            transform: translate(-50%,-50%);
            left: 50%;
            top: 50%;
          }
          @include mq(919px) {
            top: 115px;
            left: 90px;
          }
          img{
            width: 100%;
          }
        }
      }
      // .fourth-slide
      &.fourth-slide{
        .icon{
          font-size: 560px;
          position: absolute;
          top: 0;
          left: 65px;
          height: .4em;
          transform: rotate(0deg) skewX(0deg);
          @media screen and (min-width: 1601px) and (min-height: 950px) {
            top: 13%;
          }
          @media screen and (min-width: 920px) and (max-width: 1279px) {
            font-size: 700px;
            position: absolute;
            top: 10px;
            left: 172px;
            height: .4em;
            -ms-transform: rotate(0) skewX(0);
            transform: rotate(4deg) skewX(0) scaleX(1.3);
            stroke-dasharray: 7;
          }
          @include mq(919px) {
            transform: rotate(0) skewX(0) scaleX(1.3) translate(13%,48%);
            stroke-dasharray: 8;
          }
        }
        .center-wrap{
          transform: translateY(-101%);
        }
        .myths__slider__item__img {
            right: auto;
            left: 130px;
            transform: translateY(-20%);
        }
      }
      // .last-slide
      &.last-slide{
        .icon{
          font-size: 390px;
          position: absolute;
          top: 25px;
          left: 65px;
          height: .4em;
          transform: rotate(0deg) skewX(0deg);
          stroke:$red;
          @media screen and (min-width: 1601px) and (min-height: 950px) {
            top: 10%;
          }
          @include mq(919px) {
            transform: rotate(0) skewX(0) translate(10%,50%);
          }
          &:hover{
            stroke-width: 2px;
            stroke:$red;
          }
          &--#{plane} {
            font-size: 170px;
            position: absolute;
            top: 17px;
            left: auto;
            right: 121px;
            width: .4em;
            height: .4em;
            stroke-width: 0.5px;
            stroke:$red;
            &:hover{
              stroke-width: 1px;
            }
            @media screen and (min-width: 1601px) and (min-height: 950px) {
              top: 9%;
            }
            @media screen and (min-width: 920px) and (max-width: 1279px) {
              left: 41%;
              right: auto;
              transition: transform 1.5s ease-in;
            }
            @include mq(919px) {
              transition: transform 1s ease-in;
              transform: translate3d(-96px, 72px, 0px);
            }
          }
        }
        .center-wrap{
          transform: translateY(-63%);
          @media screen and (min-width: 920px) and (max-width: 1279px) {
            transform: translateY(-50%);
          }
        }
        .myths__slider__item__number{
          display: block;
          margin-bottom: 20px;
          border: 1px solid #FE5C5C;
          &:before{
            display: none;
          }
          .wrap{
            border: none;
            p{
              font-size: em(56px);
            }
          }
        }
        .myths__slider__item__img{
          right: 135px;
          @media screen and (min-width: 920px) and (max-width: 1279px) {
            transform: translateX(50%);
            right: 50%;
          }
        }
        .btn{
          &:hover{
            cursor: pointer;
            background: #fe4343;
            // box-shadow: 0 3px 1px #ccc;
            box-shadow: 4px 4px 0px $light-red, -4px 4px 0px $light-red, 4px -4px 0px $light-red, -4px -4px 0px $light-red;
          }
        }
      }
      &__#{number}{
        width: 105px;
        height: 105px;
        border-radius: 50%;
        border: 8px solid $light-red;
        display: inline-block;
        vertical-align: middle;
        margin-right: 35px;
        position: relative;
        z-index: 1;
        background: #fff;
        @include phone {
          border: 1px solid #FE5C5C!important;
          margin-right: 8px;
          vertical-align: top;
          width: 80px;
          height: 80px;
          display: inline-block !important;
          margin-bottom: 0 !important;
        }
        &:before{
          content: '';
          position: absolute;
          width: 110px;
          height: 110px;
          left: 50%;
          top: 50%;
          transform: translate(-50%,-50%);
          display: block;
          background: rgba(255, 255, 255, 0.6);
          z-index: 2;
          opacity: 1;
          transition: opacity 1.2s;
          @include phone {
            display: none;
          }
        }
        .wrap{
          height: 100%;
          border-radius: 50%;
          border: 8px solid $red;
          @include phone {
            border: none!important;
          }
          p{
            position: relative;
            top:50%;
            transform: translateY(-50%);
            margin: 0;
            line-height: auto;
            text-align: center;
            font-size: em(36px);
            font-family: 'OpenSans-bold';
            color: $red;
            @include phone {
              font-size: em(36px)!important;
            }
          }
        }
      }
      &__#{text}{
        display: inline-block;
        vertical-align: middle;
        width: 47%;
        padding-left: 6px;
        @include phone {
          width: 67%;
        }
        p{
          margin: 0;
          margin-bottom: 25px;
          &+.btn{
            margin: 0;
          }
        }
      }
      &__#{img}{
        position: absolute;
        width: auto;
        top: 50%;
        right: 0;
        transform: translateY(-31%);
        z-index: -1;
        max-width: 300px;
        @include phone {
          position: static;
          transform: none!important;
          width: 100% !important;
        }
        img{
          max-width: 290px;
        }
      }
    }
  }
}

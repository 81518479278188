@import 'https://fonts.googleapis.com/css?family=Roboto:100,300,400';
// font-family: 'Roboto', sans-serif;
@font-face {
  font-family: 'OpenSans-bold';
  src: url('../fonts/Bold/OpenSans-Bold.woff');
}
@font-face {
  font-family: 'OpenSans-Semibold';
  src: url('../fonts/Semibold/OpenSans-Semibold.woff');
}
@font-face {
  font-family: 'OpenSans-Light';
  src: url('../fonts/Light/OpenSans-Light.woff');
}
@font-face {
  font-family: 'OpenSans-Regular';
  src: url('../fonts/Regular/OpenSans-Regular.woff');
}

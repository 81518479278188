// ----------------------------
//     ABOUT PAGE
// ----------------------------
.about{
  &__#{hero}{
    background: #fff url('../img/about-hero-bg.png') 0 0 no-repeat;
    background-size: cover;
    font-size: $em-base;
    margin-bottom: 50px;
    &__#{img}{
      @include phone {
        height: auto!important;
      }
    }
    &__#{text}{
      @include phone {
        height: auto!important;
      }
      .wrap{
        position: relative;
        top: 50%;
        transform: translateY(-60%);
        @media screen and (max-width: 1023px) and (orientation:portrait) {
          transform: translateY(-30%);
        }
        @include phone {
          position: static;
          top: auto;
          transform: none;
        }
      }
      h1{
        font-size: em(60px);
        text-transform: uppercase;
        color: $red;
        @include phone {
          font-size: 25px;
        }
      }
      p{
        font-family: 'Roboto', sans-serif;
        font-weight: 300;
        line-height : 38px;
        font-size: em(28px);
        @include phone {
          font-size: 18px;
        }
      }
    }
    .anchor-nav{
      @include desktop {
        max-width: 75%;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
  &__#{info}{
    font-size: $em-base;
    @include phone {
      font-size: 13px;
    }
    .description{
       font-size : em(20px);
       line-height : 33px;
       margin-bottom: 25px;
       p{
         margin: 0;
       }
    }
    .percs{
      float: right;
      width: 100%;
      max-width: 200px;
      color: $red;
      text-align: center;
      @include phone {
        max-width: none;
      }
      &__#{item}{
        height: 200px;
        border-radius: 50%;
        border: 1px solid $light-red;
        @include phone {
          width: 135px;
          height: 135px;
          margin-bottom: 15px;
          &:first-child{
            float: left;
          }
          &:last-child{
            float: right;
          }
        }
        .wrap{
          position: relative;
          top: 50%;
          transform: translateY(-50%);
        }
        .num{
          font-size: em(58px);
          @include phone {
            font-size: 26px;
          }
        }
        .text{
          font-size: em(16px);
          color: #FF968F;
        }
        &+.percs__item{
          margin-top: 50px;
          @include phone {
            margin: 0;
          }
        }
      }

    }
    .article{
      font-family: 'Roboto', sans-serif;
      font-size: em(17px);
      p{
        // color: $gray;
        &+p{
          margin-top: 20px;
        }
        &+ul{
          margin-top: 20px;
        }
      }
      ul{
        color: $color-text;
        margin-left: 30px;
        margin-bottom: 0;
        @include phone {margin-left: 0;}
        &+p{
          margin-top: 20px;
        }
        li{
          padding:0 10px 15px;
          position: relative;
          &:last-child{
            padding-bottom: 0;
          }
          &:before{
            content: "";
            position: absolute;
            left: 0;
            top: 8px;
            display: block;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background: $red;
          }
        }
      }
    }
  }
  &__#{action}{
    font-size: $em-base;
    .icon-line{
      &:before{
        background-image: url('../img/stream-icon.png');
      }
    }
    .title{
      font-family: 'OpenSans-Light';
      font-size: em(20px);
      // max-width: 540px;
      margin: 0 auto;
      text-align: center;
      @include phone {
        font-size: 15px;
      }
      // strong{
      //   font-weight: bold;
      //   color: $red;
      // }
    }
    .features{
      margin-top: 50px;
      figure{
        display: block;
        width: 25%;
        float: left;
        text-align: center;
        @include phone {
          width: 100%;
          text-align: center;
          margin-bottom: 15px;
        }
      }
    }
  }
  &__#{best-choise}{
    font-size: $em-base;
    .icon-line{
      &:before{
        background-image: url('../img/cup-icon.png');
      }
    }
    .info{
      h2{
        font-size : em(42px);
        letter-spacing : 1.05px;
        text-transform : uppercase;
        color: $red;
        text-align: center;
        margin-bottom: 45px;
        @include phone {
          font-size: 30px;
        }
      }
      &__#{inner}{
        display: table;
        width: 100%;
        &__#{img}{
          display: table-cell;
          vertical-align: middle;
          @include phone {
            display: block;
            text-align: center;
          }
        }
        &__#{text}{
          display: table-cell;
          vertical-align: middle;
          padding-left: 40px;
          @include phone {
            display: block;
            padding-left: 0;
          }
          p{
            font-family : 'Roboto', sans-serif;
            font-size : em(17px);
            font-weight: 300;
            line-height : 27px;
            color : $gray;
            margin: 0;
            &+p{
              margin-top: 25px;
            }
          }
        }
      }
    }
  }
  &__#{bottom-banner}{
    background: #fff url('../img/about-banner.png') 0 0 no-repeat;
    background-size: cover;
    margin-top: 45px;
    @include tablet {
      background-position: -160px 0px;
    }
    @media screen and (min-width:768px) and (max-width: 920px) {
      background-position: -275px 0px;
    }
    &__#{content}{
      text-align: left;
      padding: 70px 0px 60px;
      @include phone {
        padding-left: 15px;
        padding-right: 15px;
      }
      h2{
        text-align: left;
        margin: 0;
        margin-bottom: 40px;
        @include tablet {
          font-size: 30px;
        }
        @media screen and (min-width:768px) and (max-width: 920px) {
          font-size: 25px;
        }
      }
    }
  }
}
